<template>
  <div class="c-toc" :class="active && 'c-toc--open'">
    <ol class="c-toc--list">
      <li>
        <div class="c-collapsible-checkbox">
          <span class="c-collapsible-checkbox__trigger">
            <label for="collapsible_menu_1" class="lbl-toggle">
              Toolbox Ontwikkelingsgericht Trainen
              <img
                :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                class="c-icon"
              />
            </label>
            <router-link
              to="/"
              class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
              >link</router-link
            >
          </span>
          <input
            id="collapsible_menu_1"
            class="toggle u-hidden-visually"
            type="checkbox"
            checked
          />
          <div class="c-collapsible-checkbox__content">
            <ol>
              <li><span>Ontwikkelingsgericht trainen</span></li>
              <li><span>De kalenderleeftijd</span></li>
              <li><span>De biologische leeftijd</span></li>
              <li><span>Getraindheid</span></li>
              <li>
                <span class="c-collapsible-checkbox__trigger">
                  <label for="collapsible_menu_1_1" class="lbl-toggle">
                    Hoe kwam de toolbox tot stand?
                    <img
                      :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                      class="c-icon"
                    />
                  </label>
                </span>
                <input
                  id="collapsible_menu_1_1"
                  class="toggle u-hidden-visually"
                  type="checkbox"
                />
                <div class="c-collapsible-checkbox__content">
                  <ol>
                    <li>
                      <span>Long-Term Athlete Development (LTAD) Model</span>
                    </li>
                    <li>
                      <span>Youth Physical (YPD) Development Model</span>
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <span class="c-collapsible-checkbox__trigger">
                  <label for="collapsible_menu_1_2" class="lbl-toggle">
                    Hoe gebruik je deze toolbox?<img
                      :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                      class="c-icon"
                    />
                  </label>
                </span>
                <input
                  id="collapsible_menu_1_2"
                  class="toggle u-hidden-visually"
                  type="checkbox"
                />
                <div class="c-collapsible-checkbox__content">
                  <ol>
                    <li><span>Bepaal eerst de biologische leeftijd</span></li>
                  </ol>
                </div>
              </li>
              <li><span>Referenties</span></li>
            </ol>
          </div>
        </div>
      </li>
      <li>
        <div class="c-collapsible-checkbox">
          <span class="c-collapsible-checkbox__trigger">
            <label for="collapsible_menu_2" class="lbl-toggle">
              Fysieke Ontwikkeling
              <img
                :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                class="c-icon"
              />
            </label>
            <router-link
              to="/fysieke-ontwikkeling"
              class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
              >link</router-link
            >
          </span>
          <input
            id="collapsible_menu_2"
            class="toggle u-hidden-visually"
            type="checkbox"
            checked
          />
          <div class="c-collapsible-checkbox__content">
            <ol>
              <li>
                <span>Algemeen</span>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_2_1" class="lbl-toggle"
                      >Kracht<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                    <router-link
                      to="/fysieke-ontwikkeling/kracht"
                      class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
                      >link</router-link
                    >
                  </span>
                  <input
                    id="collapsible_menu_2_1"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li><span>Algemeen</span></li>
                      <li><span>Aandachtspunten</span></li>
                      <li>
                        <span>Periode 1: Prepuberteit </span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes</span>
                            <ul>
                              <li><span>Inhoud en organisatie </span></li>
                              <li><span>Vorm </span></li>
                              <li><span>Middelen </span></li>
                              <li><span>Volume en Intensiteit </span></li>
                            </ul>
                          </li>
                          <li><span>Blessurepreventie Kracht</span></li>
                        </ol>
                      </li>
                      <li>
                        <span>
                          Periode 2: Puberteit - Voor piekgroeisnelheid</span
                        >
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes</span>
                            <ul>
                              <li><span>Inhoud en organisatie </span></li>
                              <li><span>Vorm </span></li>
                              <li><span>Middelen </span></li>
                              <li><span>Volume en Intensiteit </span></li>
                            </ul>
                          </li>
                          <li><span>Blessurepreventie Kracht</span></li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 3: Puberteit – Na piekgroeisnelheid</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes</span>
                            <ul>
                              <li><span>Inhoud en organisatie </span></li>
                              <li><span>Vorm </span></li>
                              <li><span>Middelen </span></li>
                              <li><span>Volume en Intensiteit </span></li>
                            </ul>
                          </li>
                          <li><span>Blessurepreventie Kracht</span></li>
                        </ol>
                      </li>
                      <li>
                        <span> Periode 4: Volwassen stadium</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes</span>
                            <ul>
                              <li><span>Inhoud en organisatie </span></li>
                              <li><span>Vorm </span></li>
                              <li><span>Middelen </span></li>
                              <li><span>Volume en Intensiteit </span></li>
                            </ul>
                          </li>
                          <li><span>Blessurepreventie Kracht</span></li>
                        </ol>
                      </li>
                      <li><span>Referenties</span></li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_2_2" class="lbl-toggle"
                      >Lenigheid<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                    <router-link
                      to="/fysieke-ontwikkeling/lenigheid"
                      class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
                      >link</router-link
                    >
                  </span>
                  <input
                    id="collapsible_menu_2_2"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li><span>Algemeen</span></li>
                      <li><span>Aandachtspunten</span></li>
                      <li>
                        <span>Periode 1: Prepuberteit</span>
                        <ol>
                          <li>
                            <span>Algemene richtlijnen voor stretching</span>
                            <ul>
                              <li><span>Statisch </span></li>
                              <li><span>Dynamisch </span></li>
                            </ul>
                          </li>
                          <li>
                            <span
                              >Blessurepreventie Lenigheid &amp;
                              Stretching</span
                            >
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span
                          >Periode 2: Puberteit - Voor piekgroeisnelheid</span
                        >
                        <ol>
                          <li>
                            <span>Algemene richtlijnen voor stretching</span>
                            <ul>
                              <li><span>Statisch </span></li>
                              <li><span>Dynamisch </span></li>
                            </ul>
                          </li>
                          <li>
                            <span
                              >Blessurepreventie Lenigheid &amp;
                              Stretching</span
                            >
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 3: Puberteit – Na piekgroeisnelheid</span>
                        <ol>
                          <li>
                            <span>Algemene richtlijnen voor stretching</span>
                            <ul>
                              <li><span>Statisch </span></li>
                              <li><span>Dynamisch </span></li>
                            </ul>
                          </li>
                          <li>
                            <span
                              >Blessurepreventie Lenigheid &amp;
                              Stretching</span
                            >
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 4: Volwassen stadium</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene richtlijnen voor stretching</span>
                            <ul>
                              <li><span>Statisch </span></li>
                              <li><span>Dynamisch </span></li>
                            </ul>
                          </li>
                          <li>
                            <span
                              >Blessurepreventie Lenigheid &amp;
                              Stretching</span
                            >
                          </li>
                        </ol>
                      </li>
                      <li><span>Referenties</span></li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_2_3" class="lbl-toggle"
                      >Uithouding<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                    <router-link
                      to="/fysieke-ontwikkeling/uithouding"
                      class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
                      >link</router-link
                    >
                  </span>
                  <input
                    id="collapsible_menu_2_3"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li><span>Algemeen</span></li>
                      <li><span>Aandachtspunten</span></li>
                      <li>
                        <span
                          >Voorbeeldprogramma voor beginner en gevorderde
                        </span>
                      </li>
                      <li>
                        <span>Periode 1: Prepuberteit</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes:</span>
                            <ul>
                              <li><span>Modaliteit </span></li>
                              <li><span>Frequentie </span></li>
                              <li><span>Duur </span></li>
                              <li><span>Intensiteit </span></li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span
                          >Periode 2: Puberteit - Voor piekgroeisnelheid</span
                        >
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes:</span>
                            <ul>
                              <li><span>Modaliteit </span></li>
                              <li><span>Frequentie </span></li>
                              <li><span>Duur </span></li>
                              <li><span>Intensiteit </span></li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 3: Puberteit – Na piekgroeisnelheid</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes:</span>
                            <ul>
                              <li><span>Modaliteit </span></li>
                              <li><span>Frequentie </span></li>
                              <li><span>Duur </span></li>
                              <li><span>Intensiteit </span></li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 4: Volwassen stadium</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                        </ol>
                      </li>
                      <li><span>Referenties</span></li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_2_4" class="lbl-toggle"
                      >Snelheid<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                    <router-link
                      to="/fysieke-ontwikkeling/snelheid"
                      class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
                      >link</router-link
                    >
                  </span>
                  <input
                    id="collapsible_menu_2_4"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li><span>Algemeen</span></li>
                      <li><span>Aandachtspunten</span></li>
                      <li>
                        <span>Periode 1: Prepuberteit</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes:</span>
                            <ul>
                              <li><span>Modaliteit </span></li>
                              <li><span>Volume en Intensiteit </span></li>
                              <li><span>Frequentie </span></li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span
                          >Periode 2: Puberteit - Voor piekgroeisnelheid</span
                        >
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes:</span>
                            <ul>
                              <li><span>Modaliteit </span></li>
                              <li><span>Volume en Intensiteit </span></li>
                              <li><span>Frequentie </span></li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 3: Puberteit – Na piekgroeisnelheid</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span>Algemene principes:</span>
                            <ul>
                              <li><span>Modaliteit </span></li>
                              <li><span>Volume en Intensiteit </span></li>
                              <li><span>Frequentie </span></li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 4: Volwassen stadium</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                        </ol>
                      </li>
                      <li><span>Referenties</span></li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_2_5" class="lbl-toggle"
                      >Motoriek<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                    <router-link
                      to="/fysieke-ontwikkeling/motoriek"
                      class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
                      >link</router-link
                    >
                  </span>
                  <input
                    id="collapsible_menu_2_5"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li><span>Algemeen</span></li>
                      <li>
                        <span>Periode 1: Prepuberteit</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span
                              >Blessurepreventie Motorische Competentie</span
                            >
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span
                          >Periode 2: Puberteit - Voor piekgroeisnelheid</span
                        >
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span
                              >Blessurepreventie Motorische Competentie</span
                            >
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 3: Puberteit - Na piekgroeisnelheid</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span
                              >Blessurepreventie Motorische Competentie</span
                            >
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Periode 4: Volwassen stadium</span>
                        <ol>
                          <li><span>Trainingsfocus</span></li>
                          <li>
                            <span
                              >Blessurepreventie Motorische Competentie</span
                            >
                          </li>
                        </ol>
                      </li>
                      <li><span>Referenties</span></li>
                    </ol>
                  </div>
                </div>
              </li>
              <li><span>Referenties</span></li>
            </ol>
          </div>
        </div>
      </li>
      <li>
        <div class="c-collapsible-checkbox">
          <span class="c-collapsible-checkbox__trigger">
            <label for="collapsible_menu_3" class="lbl-toggle">
              Mentale ontwikkeling<img
                :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                class="c-icon"
              />
            </label>
            <router-link
              to="/mentale-ontwikkeling"
              class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
              >link</router-link
            >
          </span>
          <input
            id="collapsible_menu_3"
            class="toggle u-hidden-visually"
            type="checkbox"
            checked
          />
          <div class="c-collapsible-checkbox__content">
            <ol>
              <li>
                <span>Algemeen</span>
              </li>
              <li>
                <span>Aandachtspunten</span>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_3_1" class="lbl-toggle"
                      >Periode 1: FUNdamentals<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_3_1"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Hoofddoel</span>
                      </li>
                      <li>
                        <span>Mentale ontwikkelingen</span>
                      </li>
                      <li>
                        <span>Mentale training</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_3_2" class="lbl-toggle"
                      >Periode 2: Learn to Train<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_3_2"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Hoofddoel</span>
                      </li>
                      <li>
                        <span>Mentale ontwikkelingen</span>
                      </li>
                      <li>
                        <span>Mentale training</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_3_3" class="lbl-toggle"
                      >Periode 3: Train to Train<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_3_3"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Hoofddoel</span>
                      </li>
                      <li>
                        <span>Mentale ontwikkelingen</span>
                      </li>
                      <li>
                        <span>Mentale training</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_3_4" class="lbl-toggle"
                      >Periode 4: Train to Compete<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_3_4"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Hoofddoel</span>
                      </li>
                      <li>
                        <span>Mentale ontwikkelingen</span>
                      </li>
                      <li>
                        <span>Mentale training</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_3_5" class="lbl-toggle"
                      >Periode 5: Train to Win
                      <img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_3_5"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Hoofddoel</span>
                      </li>
                      <li>
                        <span>Mentale ontwikkelingen</span>
                      </li>
                      <li>
                        <span>Mentale training</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <span>Referenties</span>
              </li>
              <li>
                <span>Extra lectuur</span>
              </li>
            </ol>
          </div>
        </div>
      </li>
      <li>
        <div class="c-collapsible-checkbox">
          <span class="c-collapsible-checkbox__trigger">
            <label for="collapsible_menu_4" class="lbl-toggle"
              >Sportblessures
              <img
                :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                class="c-icon"
              />
            </label>
            <router-link
              to="/sportblessures"
              class="c-button c-button--rounded c-button--primary c-button--small c-button--ghost"
              >link</router-link
            >
          </span>
          <input
            id="collapsible_menu_4"
            class="toggle u-hidden-visually"
            type="checkbox"
            checked
          />
          <div class="c-collapsible-checkbox__content">
            <ol>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_4_1" class="lbl-toggle"
                      >Algemeen<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_4_1"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Waar verschilt een kind van volwassene?</span>
                      </li>
                      <li>
                        <span>Kinderen beschikken over...</span>
                      </li>
                      <li>
                        <span>Aandachtspunten als trainer/ouder</span>
                      </li>
                      <li>
                        <span>Rode vlaggen</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_4_2" class="lbl-toggle"
                      >Periode 1: Prepuberteit<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_4_2"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Acute Letsels</span>
                        <ol>
                          <li>
                            <span>Breuk ter hoogte van de metafyse</span>
                          </li>
                          <li>
                            <span>Breuk ter hoogte van de epifyse </span>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Overbelastingsletsels</span>
                        <ol>
                          <li>
                            <span>Ziekte van Panner</span>
                          </li>
                          <li>
                            <span>Ziekte van Kohler</span>
                          </li>
                          <li>
                            <span>Ziekte van Perthes</span>
                          </li>
                          <li>
                            <span>Ziekte van Sever</span>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_4_3" class="lbl-toggle"
                      >Periode 2: Prepuberteit – voor piekgroeisnelheid<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_4_3"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Acute Letsels</span>
                        <ol>
                          <li>
                            <span>Breuk ter hoogte van de metafyse</span>
                          </li>
                          <li>
                            <span>Breuk ter hoogte van de epifyse</span>
                          </li>
                          <li>
                            <span>Avulsiefractuur of afscheuringsbreuk</span>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Overbelastingsletsels</span>
                        <ol>
                          <li><span>Ziekte van Freiberg</span></li>
                          <li><span>Little league elbow</span></li>
                          <li><span>Osteochondritis dissecans knie</span></li>
                          <li><span>Spondylolysis</span></li>
                          <li><span>Scheuermann</span></li>
                          <li><span>Epiphysiolyse</span></li>
                          <li><span>Osgood-Schlatter</span></li>
                          <li><span>Sinding-Larsen-Johansson</span></li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_4_4" class="lbl-toggle"
                      >Periode 3: Puberteit – Na piekgroeisnelheid<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_4_4"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Acute Letsels</span>
                        <ol>
                          <li>
                            <span>Avulsiefractuur of afscheuringsbreuk</span>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Overbelastingsletsels</span>
                        <ol>
                          <li><span>Apophysitis rond de heup </span></li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                <div class="c-collapsible-checkbox">
                  <span class="c-collapsible-checkbox__trigger">
                    <label for="collapsible_menu_4_5" class="lbl-toggle"
                      >Periode 4: Volwassen stadium<img
                        :src="`${$publicPath}/assets/svg-store/arrow-down.svg`"
                        class="c-icon"
                      />
                    </label>
                  </span>
                  <input
                    id="collapsible_menu_4_5"
                    class="toggle u-hidden-visually"
                    type="checkbox"
                  />
                  <div class="c-collapsible-checkbox__content">
                    <ol>
                      <li>
                        <span>Sportletsels</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </li>
      <!--<li>
        <div class="c-collapsible-checkbox">
          <span class="c-collapsible-checkbox__trigger">
            Medische aandachtspunten
            <router-link
              to="/medische-aandachtspunten"
              class="c-button c-button&#45;&#45;rounded c-button&#45;&#45;primary c-button&#45;&#45;small c-button&#45;&#45;ghost"
              >link</router-link
            >
          </span>
        </div>
      </li>-->
    </ol>
  </div>
</template>

<script>
export default {
  name: "table-of-contents",
  props: {
    active: Boolean
  }
};
</script>
