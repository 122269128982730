export const tooltips_physical = [
  {
    strength: "Ontwikkelen van coördinatie en aanleren van techniek",
    flexibility: "Aanleren correcte houding en techniek",
    motricity:
      "Ontwikkelen van brede basis via variatie in bewegingsvormen en sporten",
    speed: "Aanleren van correcte techniek en coördinatie",
    endurance: "Speelse intermittente activiteiten voor grote spiergroepen"
  },
  {
    strength: "Techniek en coördinatie – aanvang hypertrofie",
    flexibility: "Algemene en sportspecifieke oefeningen",
    motricity:
      "Gespecialiseerde sportieve vaardigheden – blijvende aandacht voor algemene motorische competentie",
    speed: "Techniek en coördinatie + aanvang kracht en vermogen",
    endurance:
      "Intermittente activiteiten voor grote spiergroepen - voldoende aandacht voor rust"
  },
  {
    strength: "Hypertrofie + verder verfijnen techniek",
    flexibility: "Sportspecifieke en algemene oefeningen",
    motricity:
      "Gespecialiseerde sportieve vaardigheden – blijvende aandacht voor algemene motorische competentie",
    speed: "Kracht en vermogen in combinatie met techniek",
    endurance: "Gestructureerde vormen van aërobe en anaërobe training"
  },
  {
    strength: "Hypertrofie + verder verfijnen techniek",
    flexibility: "Sportspecifieke en algemene oefeningen",
    motricity:
      "Gespecialiseerde sportieve vaardigheden – blijvende aandacht voor algemene motorische competentie",
    speed: "Kracht en vermogen in combinatie met techniek",
    endurance: "Gestructureerde vormen van aërobe en anaërobe training"
  }
];
export const tooltips_mental = [
  "Fundamentals",
  "Learn to Train",
  "Train to Train",
  "Train to Compete",
  "Train to Win"
];
export const tooltips_injuries = [
  "Acute of overbelastingsletsels ter hoogte van het beenderstelsel ",
  "Acute of overbelasting letsels ter hoogte van het beenderstelsel en peesaanhechtingen",
  "Acute of overbelasting letsels ter hoogte van het beenderstelsel, peesaanhechtingen en steeds meer ter hoogte van de spier-pees eenheden ",
  "Acute of overbelasting letsels ter hoogte van de spier-pees eenheden en in het spierstelsel ",
  "Acute of overbelasting letsels ter hoogte van de spier-pees eenheden en in het spierstelsel "
];

export default {
  title: "Gezond Sporten",
  agePeriods_physical_boys: [
    { id: "period_1", title: "5 – 20 jaar (alle zones)" },
    { id: "period_2", title: "5 – 11 jaar (Prepuberteit)" },
    { id: "period_3", title: "11 – 13,8 jaar (Puberteit – start tot piek)" },
    { id: "period_4", title: "13,8 – 17 jaar (Puberteit – piek tot eind)" },
    { id: "period_5", title: "17 – 20 jaar (Volwassen)" }
  ],
  agePeriods_physical_girls: [
    { id: "period_1", title: "5 – 20 jaar (alle zones)" },
    { id: "period_2", title: "5 – 9,5 jaar (Prepuberteit)" },
    { id: "period_3", title: "9,5 – 11,5 jaar (Puberteit – start tot piek)" },
    { id: "period_4", title: "11,5 – 16 jaar (Puberteit – piek tot eind)" },
    { id: "period_5", title: "16 – 20 jaar (Volwassen)" }
  ],
  agePeriods_mental_boys: [
    { id: "period_1", title: "5 – 18+ jaar (alle zones)" },
    { id: "period_2", title: "5 – 9 jaar (FUNdamentals)" },
    { id: "period_3", title: "9 – 12 jaar (Learn to train)" },
    { id: "period_4", title: "12 – 16 jaar (Train to train)" },
    { id: "period_5", title: "16 – 23 jaar (Train to compete)" },
    { id: "period_6", title: "18+ jaar (Train to win)" }
  ],
  agePeriods_mental_girls: [
    { id: "period_1", title: "5 – 19+ jaar (alle zones)" },
    { id: "period_2", title: "5 – 8 jaar (FUNdamentals)" },
    { id: "period_3", title: "8 – 11 jaar (Learn to train)" },
    { id: "period_4", title: "11 – 15 jaar (Train to train)" },
    { id: "period_5", title: "15 – 21 jaar (Train to compete)" },
    { id: "period_6", title: "19+ jaar (Train to win)" }
  ],
  agePeriods_injuries_boys: [
    { id: "period_1", title: "5 – 20 jaar (alle zones)" },
    { id: "period_2", title: "5 – 11 jaar (Prepuberteit)" },
    { id: "period_3", title: "11 – 13,8 jaar (Puberteit – start tot piek)" },
    { id: "period_4", title: "13,8 – 17 jaar (Puberteit – piek tot eind)" },
    { id: "period_5", title: "17 – 20 jaar (Volwassen)" }
  ],
  agePeriods_injuries_girls: [
    { id: "period_1", title: "5 – 20 jaar (alle zones)" },
    { id: "period_2", title: "5 – 9,5 jaar (Prepuberteit)" },
    { id: "period_3", title: "9,5 – 11,5 jaar (Puberteit – start tot piek)" },
    { id: "period_4", title: "11,5 – 16 jaar (Puberteit – piek tot eind)" },
    { id: "period_5", title: "16 – 20 jaar (Volwassen)" }
  ],
  home: {
    intro: import("./content/home/intro.md"),
    modals: {
      "modal-biological": import("./content/home/_modal-biological.md"),
      "modal-calendar": import("./content/home/_modal-calendar.md"),
      "modal-determine-biological": import(
        "./content/home/_modal-determine-biological.md"
      ),
      "modal-dev-training": import("./content/home/_modal-dev-training.md"),
      "modal-ltad": import("./content/home/_modal-ltad.md"),
      "modal-training-level": import("./content/home/_modal-training-level.md"),
      "modal-ypd": import("./content/home/_modal-ypd.md")
    },
    references: import("./content/home/references.md")
  },
  physicalDevelopment: {
    intro: import("./content/physical-development/intro.md"),
    references: import("./content/physical-development/references.md")
  },
  strength: {
    modals: {
      "modal-p1-inhoud-en-organisatie": import(
        "./content/physical-development/strength/p1/_modal-inhoud-en-organisatie.md"
      ),
      "modal-p1-middelen": import(
        "./content/physical-development/strength/p1/_modal-middelen.md"
      ),
      "modal-p1-volume-en-intensiteit": import(
        "./content/physical-development/strength/p1/_modal-volume-en-intensiteit.md"
      ),
      "modal-p1-vorm": import(
        "./content/physical-development/strength/p1/_modal-vorm.md"
      ),
      "modal-p1-injury-prevention": import(
        "./content/physical-development/strength/p1/_modal-injury-prevention.md"
      ),
      "modal-p2-inhoud-en-organisatie": import(
        "./content/physical-development/strength/p2/_modal-inhoud-en-organisatie.md"
      ),
      "modal-p2-middelen": import(
        "./content/physical-development/strength/p2/_modal-middelen.md"
      ),
      "modal-p2-volume-en-intensiteit": import(
        "./content/physical-development/strength/p2/_modal-volume-en-intensiteit.md"
      ),
      "modal-p2-vorm": import(
        "./content/physical-development/strength/p2/_modal-vorm.md"
      ),
      "modal-p2-injury-prevention": import(
        "./content/physical-development/strength/p2/_modal-injury-prevention.md"
      ),
      "modal-p3-inhoud-en-organisatie": import(
        "./content/physical-development/strength/p3/_modal-inhoud-en-organisatie.md"
      ),
      "modal-p3-middelen": import(
        "./content/physical-development/strength/p3/_modal-middelen.md"
      ),
      "modal-p3-volume-en-intensiteit": import(
        "./content/physical-development/strength/p3/_modal-volume-en-intensiteit.md"
      ),
      "modal-p3-vorm": import(
        "./content/physical-development/strength/p3/_modal-vorm.md"
      ),
      "modal-p3-injury-prevention": import(
        "./content/physical-development/strength/p3/_modal-injury-prevention.md"
      ),
      "modal-p4-injury-prevention": import(
        "./content/physical-development/strength/p4/_modal-injury-prevention.md"
      )
    },
    periods: [
      import("./content/physical-development/strength/general.md"),
      import("./content/physical-development/strength/period_1.md"),
      import("./content/physical-development/strength/period_2.md"),
      import("./content/physical-development/strength/period_3.md"),
      import("./content/physical-development/strength/period_4.md")
    ],
    references: import("./content/physical-development/strength/references.md")
  },
  flexibility: {
    periods: [
      import("./content/physical-development/flexibility/general.md"),
      import("./content/physical-development/flexibility/period_1.md"),
      import("./content/physical-development/flexibility/period_2.md"),
      import("./content/physical-development/flexibility/period_3.md"),
      import("./content/physical-development/flexibility/period_4.md")
    ],
    references: import(
      "./content/physical-development/flexibility/references.md"
    ),
    modals: {
      "modal-richtlijnen-stretching": import(
        "./content/physical-development/flexibility/_modal-richtlijnen-stretching.md"
      ),
      "modal-p1-injury-prevention": import(
        "./content/physical-development/flexibility/_modal-p1-injury-prevention.md"
      ),
      "modal-p2-injury-prevention": import(
        "./content/physical-development/flexibility/_modal-p2-injury-prevention.md"
      ),
      "modal-p3-injury-prevention": import(
        "./content/physical-development/flexibility/_modal-p3-injury-prevention.md"
      ),
      "modal-p4-injury-prevention": import(
        "./content/physical-development/flexibility/_modal-p4-injury-prevention.md"
      )
    }
  },
  motricity: {
    periods: [
      import("./content/physical-development/motricity/general.md"),
      import("./content/physical-development/motricity/period_1.md"),
      import("./content/physical-development/motricity/period_2.md"),
      import("./content/physical-development/motricity/period_3.md"),
      import("./content/physical-development/motricity/period_4.md")
    ],
    modals: {
      "modal-p1-injury-prevention": import(
        "./content/physical-development/motricity/_modal-p1-injury-prevention.md"
      ),
      "modal-p2-injury-prevention": import(
        "./content/physical-development/motricity/_modal-p2-injury-prevention.md"
      ),
      "modal-p3-injury-prevention": import(
        "./content/physical-development/motricity/_modal-p3-injury-prevention.md"
      ),
      "modal-p4-injury-prevention": import(
        "./content/physical-development/motricity/_modal-p4-injury-prevention.md"
      )
    },
    references: import("./content/physical-development/motricity/references.md")
  },
  speed: {
    modals: {
      "modal-p1-modaliteit": import(
        "./content/physical-development/speed/p1/_modal-modaliteit.md"
      ),
      "modal-p1-frequentie": import(
        "./content/physical-development/speed/p1/_modal-frequentie.md"
      ),
      "modal-p1-volume-en-intensiteit": import(
        "./content/physical-development/speed/p1/_modal-volume-en-intensiteit.md"
      ),
      "modal-p2-modaliteit": import(
        "./content/physical-development/speed/p2/_modal-modaliteit.md"
      ),
      "modal-p2-frequentie": import(
        "./content/physical-development/speed/p2/_modal-frequentie.md"
      ),
      "modal-p2-volume-en-intensiteit": import(
        "./content/physical-development/speed/p2/_modal-volume-en-intensiteit.md"
      ),
      "modal-p3-modaliteit": import(
        "./content/physical-development/speed/p3/_modal-modaliteit.md"
      ),
      "modal-p3-frequentie": import(
        "./content/physical-development/speed/p3/_modal-frequentie.md"
      ),
      "modal-p3-volume-en-intensiteit": import(
        "./content/physical-development/speed/p3/_modal-volume-en-intensiteit.md"
      )
    },
    periods: [
      import("./content/physical-development/speed/general.md"),
      import("./content/physical-development/speed/period_1.md"),
      import("./content/physical-development/speed/period_2.md"),
      import("./content/physical-development/speed/period_3.md"),
      import("./content/physical-development/speed/period_4.md")
    ],
    references: import("./content/physical-development/speed/references.md")
  },
  endurance: {
    modals: {
      "modal-intro": import(
        "./content/physical-development/endurance/_modal-intro.md"
      ),
      "modal-p1-modaliteit": import(
        "./content/physical-development/endurance/p1/_modal-modaliteit.md"
      ),
      "modal-p1-frequentie": import(
        "./content/physical-development/endurance/p1/_modal-frequentie.md"
      ),
      "modal-p1-duur": import(
        "./content/physical-development/endurance/p1/_modal-duur.md"
      ),
      "modal-p1-intensiteit": import(
        "./content/physical-development/endurance/p1/_modal-intensiteit.md"
      ),
      "modal-p2-modaliteit": import(
        "./content/physical-development/endurance/p2/_modal-modaliteit.md"
      ),
      "modal-p2-frequentie": import(
        "./content/physical-development/endurance/p2/_modal-frequentie.md"
      ),
      "modal-p2-duur": import(
        "./content/physical-development/endurance/p2/_modal-duur.md"
      ),
      "modal-p2-intensiteit": import(
        "./content/physical-development/endurance/p2/_modal-intensiteit.md"
      ),
      "modal-p3-modaliteit": import(
        "./content/physical-development/endurance/p3/_modal-modaliteit.md"
      ),
      "modal-p3-frequentie": import(
        "./content/physical-development/endurance/p3/_modal-frequentie.md"
      ),
      "modal-p3-duur": import(
        "./content/physical-development/endurance/p3/_modal-duur.md"
      ),
      "modal-p3-intensiteit": import(
        "./content/physical-development/endurance/p3/_modal-intensiteit.md"
      )
    },
    periods: [
      import("./content/physical-development/endurance/general.md"),
      import("./content/physical-development/endurance/period_1.md"),
      import("./content/physical-development/endurance/period_2.md"),
      import("./content/physical-development/endurance/period_3.md"),
      import("./content/physical-development/endurance/period_4.md")
    ],
    references: import(
      "./content/physical-development/endurance/references.md"
    ),
    modal: import("./content/physical-development/endurance/_modal-intro.md")
  },
  mental: {
    intro: import("./content/mental-development/general.md"),
    references: import("./content/mental-development/references.md")
  },
  mentalPeriods: {
    periods: [
      import("./content/empty.md"),
      import("./content/mental-development/period_1.md"),
      import("./content/mental-development/period_2.md"),
      import("./content/mental-development/period_3.md"),
      import("./content/mental-development/period_4.md"),
      import("./content/mental-development/period_5.md")
    ]
  },
  injuries: {
    modals: {
      "modal-image-01": import("./content/injuries/modals/_modal-image-01.md")
    },
    intro: import("./content/injuries/general.md")
  },
  injuriesPeriods: {
    modals: {
      "modal-image-02": import("./content/injuries/modals/_modal-image-02.md"),
      "modal-image-03": import("./content/injuries/modals/_modal-image-03.md"),
      "modal-image-04": import("./content/injuries/modals/_modal-image-04.md"),
      "modal-metafyse": import("./content/injuries/modals/_modal-metafyse.md"),
      "modal-epifyse": import("./content/injuries/modals/_modal-epifyse.md"),
      "modal-avulsie": import("./content/injuries/modals/_modal-avulsie.md"),
      "modal-panner": import("./content/injuries/modals/_modal-panner.md"),
      "modal-kohler": import("./content/injuries/modals/_modal-kohler.md"),
      "modal-perthes": import("./content/injuries/modals/_modal-perthes.md"),
      "modal-sever": import("./content/injuries/modals/_modal-sever.md"),
      "modal-freiberg": import("./content/injuries/modals/_modal-freiberg.md"),
      "modal-elbow": import("./content/injuries/modals/_modal-elbow.md"),
      "modal-knie": import("./content/injuries/modals/_modal-knie.md"),
      "modal-spondylolysis": import(
        "./content/injuries/modals/_modal-spondylolysis.md"
      ),
      "modal-scheuermann": import(
        "./content/injuries/modals/_modal-scheuermann.md"
      ),
      "modal-epiphysiolyse": import(
        "./content/injuries/modals/_modal-epiphysiolyse.md"
      ),
      "modal-osgood-schlatter": import(
        "./content/injuries/modals/_modal-osgood-schlatter.md"
      ),
      "modal-sinding-larsen-johansson": import(
        "./content/injuries/modals/_modal-sinding-larsen-johansson.md"
      ),
      "modal-avulsie-2": import(
        "./content/injuries/modals/_modal-avulsie-2.md"
      ),
      "modal-heup": import("./content/injuries/modals/_modal-heup.md")
    },
    periods: [
      import("./content/empty.md"),
      import("./content/injuries/period_1.md"),
      import("./content/injuries/period_2.md"),
      import("./content/injuries/period_3.md"),
      import("./content/injuries/period_4.md")
    ]
  }
};
