import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import marked from "marked";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const genders = [
  { label: "Jongens", value: "boys" },
  { label: "Beide", value: "both" },
  { label: "Meisjes", value: "girls" }
];

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    agePeriod: 0,
    gender: genders[1],
    genders
  },
  mutations: {
    SET_AGE_PERIOD: (state, period) => {
      state.agePeriod = period;
    },
    SET_GENDER: (state, gender) => {
      state.gender = gender;
    }
  },
  actions: {
    setAgePeriod({ commit }, period) {
      commit("SET_AGE_PERIOD", period);
    },
    setGender({ commit }, gender) {
      commit("SET_GENDER", gender);
    },
    getContent({ commit }, path) {
      return axios.get(path).then(response => {
        return marked(response.data, { sanitize: true });
      });
    }
  }
});
