<template>
  <main class="o-flex o-flex--justify-center o-flex--align-center">
    <section class="c-content">
      <h3>Het spijt me. <br/>Hier wordt er niets gevonden.</h3>
      <p>Terug naar <router-link class="c-button c-button--rounded c-button--ghost c-button--primary" to="/">Home</router-link></p>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "NotFound",
  components: {}
};
</script>
