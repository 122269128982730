<template>
  <div class="c-wrapper">
    <Menu />
    <router-view />
    <Footer />

    <a11y-dialog
      disable-native
      id="app-dialog"
      :class-names="{
        base: '',
        overlay: 'c-modal__overlay',
        element: 'c-modal__modal',
        document: 'c-modal__content-wrapper',
        title: 'c-modal__title',
        closeButton: 'c-button c-modal__close-button'
      }"
      app-root="#app"
      dialog-root="#dialog-root"
      @dialog-ref="assignDialogRef"
    >
      <template v-slot:title>
        <span>{{ modalTitle }}</span>
      </template>
      <div class="c-modal__body c-content__text" v-html="modalBody"></div>
    </a11y-dialog>
  </div>
</template>

<style src="../src/_scss/all.scss" lang="scss" />

<script>
import Menu from "@/components/Menu";
import content from "@/content";
import Footer from "@/components/Colofon";

export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
      dialog: null,
      activeModal: "",
      modalTitle: "",
      modalBody: ""
    };
  },
  watch: {
    $route: {
      handler: function(to, from) {
        if (to.hash.indexOf("modal") >= 0) {
          this.activeModal = to.hash ? to.hash.replace("#", "") : "";
          console.log("test", this.activeModal);

          if (this.dialog) {
            this.$nextTick(() => {
              this.openDialog();
            });
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    openDialog() {
      if (!this.page) return;
      // console.log("OPEN DIALOG", this.activeModal, content[this.page].modals);
      console.log(this.page);
      if (
        this.activeModal &&
        content[this.page].modals &&
        content[this.page].modals[this.activeModal]
      ) {
        // document.querySelector("html").style.overflow = "hidden";
        // document.querySelector("body").style.overflow = "hidden";
        this.modalTitle = "";
        this.dialog.container.classList.add("c-modal");

        content[this.page].modals[this.activeModal].then(response => {
          this.modalBody = response.default.replaceAll(
            "/assets",
            `${this.$publicPath}/assets`
          );
        });

        this.dialog.show();
      }
    },
    hideDialog() {
      console.log("HIDE DIALOG");
      if (this.dialog) {
        this.dialog.container.classList.remove("c-modal");
        // document.querySelector("html").style.overflow = "auto";
        // document.querySelector("body").style.overflow = "auto";

        this.$router.replace(this.$route.path);
        this.dialog.hide();
        this.activeModal = "";
        this.modalBody = "";
      }
    },
    assignDialogRef(dialog) {
      if (!dialog) return;
      this.dialog = dialog;

      this.dialog.on("hide", dialog => {
        this.hideDialog();
      });
      this.openDialog();
    }
  },
  computed: {
    page() {
      return this.$route.meta.id;
    }
  }
};
</script>
