import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import appRoutes from "@/appRoutes";
import NotFound from "@/views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      id: "home"
    }
  },
  ...appRoutes,
  { path: "content" }, // Ignore or pass on to server
  { path: "*", component: NotFound }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
