<template>
  <div class="c-footer">
    <div class="c-footer__logo">
      <img :src="`${$publicPath}/assets/logo.svg`" alt="Gezond Sporten" />
    </div>
    <div class="c-footer__image">
      <img
        :src="`${$publicPath}/assets/sport-vlaanderen.svg`"
        alt="Sport Vlaanderen"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "colofon",
  props: {
    // active: Boolean
  }
};
</script>
