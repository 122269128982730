var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-wrapper"},[_c('Menu'),_c('router-view'),_c('Footer'),_c('a11y-dialog',{attrs:{"disable-native":"","id":"app-dialog","class-names":{
      base: '',
      overlay: 'c-modal__overlay',
      element: 'c-modal__modal',
      document: 'c-modal__content-wrapper',
      title: 'c-modal__title',
      closeButton: 'c-button c-modal__close-button'
    },"app-root":"#app","dialog-root":"#dialog-root"},on:{"dialog-ref":_vm.assignDialogRef},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.modalTitle))])]},proxy:true}])},[_c('div',{staticClass:"c-modal__body c-content__text",domProps:{"innerHTML":_vm._s(_vm.modalBody)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }