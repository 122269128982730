<template>
  <div class="c-header">
    <h1 class="c-header__logo">
      <router-link to="/">
        <img :src="`${$publicPath}/assets/logo.svg`" :alt="title" />
        <span class="u-hidden-visually">{{ title }}</span>
      </router-link>
    </h1>
    <nav class="c-nav c-nav--primary">
      <router-link
        class="c-nav__item"
        v-for="page in pages"
        :key="page.path"
        :to="page.path"
      >
        {{ page.name }}
      </router-link>
    </nav>
    <button
      class="c-toc__trigger c-button--rounded c-button c-button--primary"
      type="button"
      @click="activateToc"
    >
      Inhoudstafel
    </button>
    <table-of-contents :active="tocOpen" />
  </div>
</template>
<script>
import content from "@/content";
import appRoutes from "@/appRoutes";
import TableOfContents from "@/components/TableOfContents";

export default {
  name: "Menu",
  components: { "table-of-contents": TableOfContents },

  data() {
    return {
      title: content.title,
      tocOpen: false
    };
  },
  computed: {
    pages() {
      return appRoutes;
    }
  },
  methods: {
    activateToc: function() {
      this.tocOpen = !this.tocOpen;
      return this.tocOpen;
    }
  }
};
</script>
