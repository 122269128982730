<template>
  <main>
    <section class="c-content o-layout">
      <div class="o-layout__item c-content__text">
        <h2>Gezond Sporten - Home</h2>
      </div>
      <div
        class="o-layout__item u-1-of-2-at-medium c-content__text"
        v-html="htmlContent"
      />
      <div
        class="o-layout__item u-1-of-2-at-medium c-content__main-image-wrapper"
      >
        <img
          class="c-content__main-image u-1-of-2"
          :src="`${$publicPath}/assets/boys-strength.png`"
          alt=""
        />
        <img
          class="c-content__main-image u-1-of-2"
          :src="`${$publicPath}/assets/girls-strength.png`"
          alt=""
        />
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import content from "@/content";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      dialogs: {},
      htmlContent: "",
      htmlContentReferences: ""
    };
  },
  mounted() {
    this.setContentText();
    this.setReferences();
  },
  computed: {
    page() {
      console.log("page", this.$route.meta.id);
      return this.$route.meta.id;
    }
  },
  methods: {
    setContentText() {
      content[this.page].intro.then(response => {
        if (response) {
          this.htmlContent = response.default.replaceAll(
            "/assets",
            `${this.$publicPath}/assets`
          );
        }
      });
    },
    setReferences() {
      content[this.page].references.then(response => {
        this.htmlContentReferences = response.default.replaceAll(
          "/assets",
          `${this.$publicPath}/assets`
        );
      });
    }
  }
};
</script>
