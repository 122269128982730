export default [
  {
    path: "/fysieke-ontwikkeling",
    name: "Fysieke ontwikkeling",
    component: () =>
      import(
        /* webpackChunkName: "physicaldevelopment" */ "./views/PhysicalDevelopment.vue"
      ),
    children: [
      {
        path: "",
        name: "Algemeen",
        meta: {
          id: "intro",
          type: "physical"
        }
      },
      {
        path: "kracht",
        name: "Kracht",
        meta: {
          id: "strength",
          type: "physical"
        },
        component: () =>
          import(/* webpackChunkName: "chartpage" */ "./views/ChartPage.vue")
      },
      {
        path: "lenigheid",
        name: "Lenigheid",
        meta: {
          id: "flexibility",
          type: "physical"
        },
        component: () =>
          import(/* webpackChunkName: "chartpage" */ "./views/ChartPage.vue")
      },
      {
        path: "uithouding",
        name: "Uithouding",
        meta: {
          id: "endurance",
          type: "physical"
        },
        component: () =>
          import(/* webpackChunkName: "chartpage" */ "./views/ChartPage.vue")
      },
      {
        path: "snelheid",
        name: "Snelheid",
        meta: {
          id: "speed",
          type: "physical"
        },
        component: () =>
          import(/* webpackChunkName: "pd-kracht" */ "./views/ChartPage.vue")
      },
      {
        path: "motoriek",
        name: "Motoriek",
        meta: {
          id: "motricity",
          type: "physical"
        },
        component: () =>
          import(/* webpackChunkName: "chartpage" */ "./views/ChartPage.vue")
      }
    ]
  },
  {
    path: "/mentale-ontwikkeling",
    name: "Mentale ontwikkeling",
    component: () =>
      import(
        /* webpackChunkName: "mental-development" */ "./views/MentalDevelopment.vue"
      ),
    children: [
      {
        path: "",
        name: "Algemeen",
        meta: {
          id: "intro",
          type: "mental"
        }
      },
      {
        path: "groeifases",
        name: "Per Groeifase",
        meta: {
          id: "mentalPeriods",
          type: "mental"
        },
        component: () =>
          import(/* webpackChunkName: "chartpage" */ "./views/ChartPage.vue")
      }
    ]
  },
  {
    path: "/sportblessures",
    name: "Sportblessures",
    component: () =>
      import(/* webpackChunkName: "injuries" */ "./views/Injuries.vue"),
    children: [
      {
        path: "",
        name: "Algemeen",
        meta: {
          id: "injuries",
          type: "injuries"
        }
      },
      {
        path: "groeifases",
        name: "Per Groeifase",
        meta: {
          id: "injuriesPeriods",
          type: "injuries"
        },
        component: () =>
          import(/* webpackChunkName: "chartpage" */ "./views/ChartPage.vue")
      }
    ]
  }
  // {
  //   path: "/medische-aandachtspunten",
  //   name: "Medische aandachtspunten",
  //   meta: {
  //     id: "medical",
  //     type: "medicalPage"
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "page" */ "./views/Medical.vue")
  // }
];
